<template>
    <div class="suppliers" v-if="$can('supplier_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="imageDialog"
        >
          <v-card max-width="600">
            <v-toolbar flat>
              Upload Logo
              <v-spacer></v-spacer>
              <v-btn icon @click="imageDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
    
            <v-form ref="imageForm" v-model="imageValid" lazy-validation>
              <v-layout column>
                <v-flex xs12>
                  <v-file-input
                    class="mx-2 mt-3"
                    :loading="imageLoading"
                    :rules="fileRules"
                    filled
                    dense
                    show-size
                    chips
                    accept="image/*"
                    v-model="imageFile"
                    label="Select Image"
                  ></v-file-input>
                </v-flex>
                <v-flex xs12>
                  <div class="mx-3 mb-5">
                    <v-btn
                      depressed
                      block
                      class="primary text-none"
                      @click="uploadImage"
                      :loading="imageLoading"
                      :disabled="!imageValid"
                    >
                      Submit <v-icon right dark>mdi-upload</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </v-dialog>
    <div class="pa-5">
        <v-layout column>
          <div v-if="view == 'default'">
            <v-card elevation="0" class="mt-5">
              
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md6> 
                        <div v-if="length != 0" align="left" class="mt-1">
                            <b class="display-1 primary--text">Total: </b
                            >{{ supplierPagination.total | formatNumber }}
                        </div>
                    </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field
                          class="text_field background"
                          outlined
                          dense
                          rounded
                          label="Search"
                          append-icon="mdi-undo-variant"
                          @click:append="resetSearch()"
                          v-on:keyup.enter="search"
                          v-model="searchTerm"
                          @input="enableSearch()"
                        ></v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1" align="right">
                        <v-btn
                          class="button mx-1"
                          icon
                          @click="search"
                          :loading="searchLoader"
                          :disabled="searchButtonDisabled"
                        >
                          <v-icon class="secondary--text">mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                          class="button mx-1"
                          @click="changeView('create')"
                          v-if="$can('supplier_create')"
                        >
                        
                          <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-divider class="mt-9 mx-5"></v-divider>
              <v-progress-linear
                v-if="supplierLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-flex xs12 class="mt-5">
                <div v-if="suppliers.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="primary--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              No Supplier Found
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="accent">
                              <th class="text-left text--text">Logo</th>
                              <th class="text-left text--text">Name</th>
                              <th class="text-left text--text">Number</th>
                              <th class="text-left text--text">County</th>
                              <th class="text-left text--text">Status</th>
                              <th class="text-left text--text">Date Created</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in suppliers" :key="item.id">
                              <td>
                                <div class="my-2">
                                  <v-avatar size="48" v-if="item.logo==null">
                                    <v-img
                                      max-height="150"
                                      
                                      src="img/manufacturer1.png"
                                    >
                                    </v-img>
                                  </v-avatar>
                                  <v-avatar size="48" v-else>
                                    <v-img
                                        max-height="150"
                                        :src="path+'/storage/supplier_pictures/'+item.logo"
                                      >
                                      </v-img>
                                  </v-avatar>
                                </div>
                              </td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.supplier_no }}</td>
                              <td>
                                <div v-if="item.county_id != null">
                                  {{ item.county.name }}
                                </div>
                                <div v-else>
                                  N/A
                                </div>
                              </td>                              
                              <td>
                                <div v-if="item.status == 1">Active</div>
                                <div v-else>Inactive</div>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-btn
                                    icon
                                    v-if="$can('supplier_view')"
                                    @click="showSupplier(item)"
                                    class="button mr-1 universal--text"
                                  >
                                    <v-icon small> mdi-eye </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="$can('supplier_edit')"
                                    icon
                                    class="button mr-1 success--text"
                                    @click="editSupplier(item)"
                                  >
                                    <v-icon small> mdi-pencil </v-icon>
                                  </v-btn>
                                  <v-btn
                                    v-if="$can('supplier_archive')"
                                    icon
                                    class="button mr-1 error--text"
                                    @click="deleteSupplier(item)"
                                    :loading="
                                      loading && supplierIndex == item.id
                                    "
                                  >
                                    <v-icon small> mdi-delete </v-icon>
                                  </v-btn>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('supplier_activate') &&
                                      item.status == 0
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 red--text"
                                        @click="activateSupplier(item)"
                                        :loading="
                                          activateLoading &&
                                          supplierIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small>
                                          mdi-toggle-switch-off-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Activate Supplier</span>
                                  </v-tooltip>
                                  <v-tooltip
                                    top
                                    v-if="
                                      $can('supplier_deactivate') &&
                                      item.status == 1
                                    "
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 green--text"
                                        @click="deactivateSupplier(item)"
                                        :loading="
                                          deactivateLoading &&
                                          supplierIndex == item.id
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Deactivate Supplier</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </div>
                  <div class="hidden-md-and-up">
                    <div>
                      <v-layout column>
                        <template v-for="(supplier, index) in suppliers">
                          <div :key="index">
                            <v-flex xs12 class="mb-2">
                              <v-card elevation="0" style="border-radius: 25px;" outlined>
                                <div class="pa-5">
                                  <v-layout column>
                                    <v-flex xs12>
                                      <v-layout row wrap>
                                          <v-flex xs2>
                                          <div align="center">
                                          <v-avatar size="48" v-if="supplier.display_img==null">
                                          <v-img
                                              max-height="150"
                                              
                                              src="img/manufacturer1.png"
                                          >
                                          </v-img>
                                          </v-avatar>
                                          <v-avatar size="48" v-else>
                                          <v-img
                                              max-height="150"
                                              :src="path+'/storage/supplier_pictures/'+supplier.display_img"
                                              >
                                              </v-img>
                                          </v-avatar>
                                      </div>
                                      </v-flex>
                                      <v-flex xs9>
                                          <div class="title primary--text ml-3">
                                          <b>{{ supplier.name }}</b>
                                          </div>
                                      </v-flex>
                                      <v-flex xs1>
                                          <v-btn
                                          depressed
                                          class="red--text"
                                          :loading="
                                              loading && supplierIndex == supplier.id
                                          "
                                          icon
                                          @click="deleteSupplier(supplier)"
                                          v-if="$can('supplier_archive')"
                                          >
                                          <v-icon> mdi-delete </v-icon>
                                          </v-btn>
                                      </v-flex>
                                      </v-layout>
                                      
                                  </v-flex>
                                  <v-divider class="mt-7 mb-3"></v-divider>

                                  
                                    <v-flex xs12 class="mt-1">
                                      <v-layout column>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>County</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              <div v-if="supplier.county_id != null">
                                                {{ supplier.county.name }}
                                              </div>
                                              <div v-else>
                                                N/A
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Status</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              <div v-if="supplier.status == 1">Active</div>
                                              <div v-else>Inactive</div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs4>
                                              <b>Date Created</b>
                                            </v-flex>
                                            <v-flex xs8>
                                              {{
                                                supplier.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 class="mt-5">
                                          <v-layout row wrap>
                                            <v-flex xs12 v-if="$can('supplier_view')">
                                              <div>
                                                <v-btn
                                                  rounded
                                                  depressed
                                                  class="primary text-none white--text mt-3"
                                                  block
                                                  @click="
                                                    showSupplier(supplier)
                                                  "
                                                  
                                                  >View
                                                  <v-icon right> mdi-eye </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('supplier_edit')">
                                              <div>
                                                <v-btn
                                                rounded
                                                  depressed
                                                  class="blue text-none white--text mt-1"
                                                  block
                                                  @click="
                                                    editSupplier(supplier)
                                                  "
                                                  
                                                  >Edit
                                                  <v-icon right>
                                                    mdi-border-color
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('supplier_activate') &&
                                                    supplier.status == 0">
                                              <div>
                                                <v-btn
                                                rounded
                                                  depressed
                                                  class="green text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    activateLoading &&
                                                    supplierIndex == supplier.id
                                                  "
                                                  @click="
                                                    activateSupplier(supplier)
                                                  "
                                                  
                                                  >Activate Supplier
                                                  <v-icon right>
                                                    mdi-toggle-switch-off-outline
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('supplier_deactivate') &&
                                                    supplier.status == 1">
                                              <div>
                                                <v-btn
                                                rounded
                                                  depressed
                                                  class="red text-none white--text mt-1"
                                                  block
                                                  :loading="
                                                    deactivateLoading &&
                                                    supplierIndex == supplier.id
                                                  "
                                                  @click="
                                                    deactivateSupplier(supplier)
                                                  "
                                                  
                                                  >Deactivate Supplier
                                                  <v-icon right>
                                                    mdi-toggle-switch
                                                  </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                            <v-flex xs12 v-if="$can('log_view')" >
                                              <div class="mt-1">
                                                <v-btn
                                                  rounded
                                                  block
                                                  depressed
                                                  class="purple text-none white--text"
                                                  @click="viewLog(supplier)"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                > Log
                                                  <v-icon small> mdi-script-text </v-icon>
                                                </v-btn>
                                              </div>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-card>
                            </v-flex>
                          </div>
                        </template>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-card>
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="left">
                  <v-pagination
                    v-if="length != 0"
                    :length="length"
                    total-visible="10"
                    v-model="supplierPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              
            </v-layout>
          </div>
          <div v-if="view == 'create'">
            <v-flex class="mt-5">
              <v-card outlined>
                <v-card-title class="accent text--text">
                  <v-img
                    v-if="newSupplier.id != null"
                    src="img/badge.png"
                    max-width="40"
                  ></v-img>
                  <v-spacer></v-spacer>
                  <div v-if="newSupplier.id == null">Add Supplier</div>
                  <div v-else>Edit Supplier</div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="changeView('default')">
                    <v-icon class="text--text"> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout row wrap class="mt-5">
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Name<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newSupplier.name"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
  
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Supplier No.</div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newSupplier.supplier_no"
                          ></v-text-field>
                        </div>
                      </v-flex>

                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>County</div>
                          <v-select
                            :items="formData.counties"
                            v-model="newSupplier.county_id"
                            class="text_field background my-2"
                            item-value="id"
                            item-text="name"
                            outlined
                            dense
                          ></v-select>
                        </div>
                      </v-flex>
                  
                     
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>Description</div>
                          <div class="black--text">
                            <ckeditor
                              :editor="editor"
                              v-model="newSupplier.description"
                              :config="editorConfig"
                            ></ckeditor>
                          </div>                          
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div class="pa-3">
                          <div>
                            Physical Address
                          </div>
                          <v-text-field
                            class="text_field background my-2"
                            outlined
                            dense
                            v-model="newSupplier.physical_address"
                          ></v-text-field>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    class="primary text-none mt-3"
                    @click="save"
                    :disabled="!valid"
                    :loading="loading"
                  >
                    Save
                    <v-icon right> mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </div>
          <div v-if="view == 'show'">
            <v-card outlined class="mt-5">
              <v-progress-linear
                v-if="supplierStockLoader"
                height="1"
                indeterminate
                color="primary"
              >
              </v-progress-linear>
              <v-card-title class="accent text--text">
                <v-spacer></v-spacer>
                <div>View Supplier</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div class="pa-1">
                  <v-tabs show-arrows v-model="supplierTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>
  
                    <v-tab @click="activeTab = 'details'">
                      <div class="text-none primary--text">Details</div>
                    </v-tab>
                    <v-tab v-if="$can('stock_view')"
                      @click="activeTab = 'supplierStocks'"
                    >
                      <div class="text-none primary--text">
                        Stocks ({{
                          supplierStockPagination.total
                        }})
                      </div>
                    </v-tab>
                  </v-tabs>
                  <div v-if="activeTab == 'details'">
                    <v-card-text>
                      <div class="pa-3">
                        <v-layout row wrap>
                          <v-flex xs12 md2>
                            <div align="center">
                                <v-avatar
                                  size="86"
                                >
                                  <v-img
                                    v-if="assignedSupplier.logo==null"
                                    src="img/manufacturer1.png"
                                  >
                                  </v-img>
                                  <v-img
                                    v-else
                                    :src="path+'/storage/supplier_pictures/'+assignedSupplier.logo"
                                  >
                                  </v-img>
                                </v-avatar>
                              
                            </div>
                            <div align="center" class="mt-5">
                              <v-btn class="primary text-none" depressed @click="imageDialog = true" v-if="$can('supplier_image_upload')">
                                Upload Logo
                                <v-icon right>mdi-file-upload</v-icon>
                              </v-btn>
                            </div>
                          </v-flex>
                          <v-flex xs12 md10>
                            <div class="mx-8">
                            <v-layout column>
                              <v-flex xs12>
                                <v-layout row wrap>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Supplier Name: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{ assignedSupplier.name }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>County: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              <div v-if="assignedSupplier.county_id != null">
                                                {{ assignedSupplier.county.name }}
                                              </div>
                                              <div v-else>
                                                N/A
                                              </div>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 md6>
                                    <v-layout column>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Status: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div
                                              v-if="
                                                assignedSupplier.status == 1
                                              "
                                            >
                                              Active
                                            </div>
                                            <div v-else>Inactive</div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs12 class="mt-6">
                                        <v-layout row wrap>
                                          <v-flex xs12 md4>
                                            <b>Date Created: </b>
                                          </v-flex>
                                          <v-flex xs12 md8>
                                            <div>
                                              {{
                                                assignedSupplier.created_at
                                                  | moment("DD/MM/YYYY - hh:mm a")
                                              }}
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-card-text>
                    <v-card-title class="accent text--text mt-5">
                      <v-spacer></v-spacer>
                      <div>Supplier Contact Details</div>
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click="changeContactView('create')"
                        v-if="
                          $can('supplier_contact_create') &&
                          contactView == 'default' &&
                          assignedSupplier.supplier_contact == null
                        "
                      >
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </v-btn>
  
                      <v-btn
                        icon
                        @click="changeContactView('default')"
                        v-if="contactView != 'default'"
                      >
                        <v-icon> mdi-close </v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="contactView == 'default'">
                        <div class="pa-3 mt-5">
                          <div
                            v-if="
                              assignedSupplier.supplier_contact == null
                            "
                          >
                            <v-layout row wrap>
                              <v-flex xs12 md1>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      <v-icon large class="primary--text">
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                              <v-flex xs12 md11>
                                <v-container fill-height fluid>
                                  <v-row align="center" justify="center">
                                    <v-col class="text-center">
                                      Contacts Not Added
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-flex>
                            </v-layout>
                          </div>
                          <div v-else>
                            <v-layout column>
                              <v-flex xs12>
                                <div class="ml-3">
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <v-layout row wrap>
                                        <v-flex xs12 md6>
                                          <v-layout column>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Primary Email: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .primary_email != null
                                                    "
                                                  >
                                                    {{
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .primary_email
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Secondary Email: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .secondary_email != null
                                                    "
                                                  >
                                                    {{
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .secondary_email
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Website URL: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .web_url != null
                                                    "
                                                  >
                                                    <a
                                                      :href="
                                                        assignedSupplier
                                                          .supplier_contact
                                                          .web_url
                                                      "
                                                      target="_blank"
                                                      style="
                                                        text-decoration: none;
                                                      "
                                                      >{{
                                                        assignedSupplier
                                                          .supplier_contact
                                                          .web_url
                                                      }}</a
                                                    >
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                          <v-layout column>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Primary Phone #: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .primary_phone != null
                                                    "
                                                  >
                                                    {{
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .primary_phone
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                            <v-flex xs12 class="mt-6">
                                              <v-layout row wrap>
                                                <v-flex xs12 md4>
                                                  <b>Secondary Phone #: </b>
                                                </v-flex>
                                                <v-flex xs12 md8>
                                                  <div
                                                    v-if="
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .secondary_phone != null
                                                    "
                                                  >
                                                    {{
                                                      assignedSupplier
                                                        .supplier_contact
                                                        .secondary_phone
                                                    }}
                                                  </div>
                                                  <div v-else>N/A</div>
                                                </v-flex>
                                              </v-layout>
                                            </v-flex>
                                          </v-layout>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn
                                        icon
                                        @click="
                                          editContact(
                                            assignedSupplier.supplier_contact
                                          )
                                        "
                                        v-if="$can('supplier_contact_edit')"
                                      >
                                        <v-icon small class="green--text"
                                          >mdi-pencil</v-icon
                                        >
                                      </v-btn>
                                      <v-btn
                                        icon
                                        @click="
                                          deleteContact(
                                            assignedSupplier.supplier_contact
                                          )
                                        "
                                        v-if="
                                          $can('supplier_contact_archive')
                                        "
                                        :loading="loading"
                                      >
                                        <v-icon small class="red--text"
                                          >mdi-delete</v-icon
                                        >
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </div>
                      </div>
                      <div v-if="contactView == 'create'" class="mt-5">
                        <v-form v-model="valid" ref="contactForm" lazy-validation>
                          <v-layout row wrap>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Primary Email</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.primary_email"
                                  :rules="emailRules"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Secondary Email</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.secondary_email"
                                  :rules="emailRules"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Primary Phone Number</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.primary_phone"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Secondary Phone Number</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.secondary_phone"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12 md4>
                              <div class="pa-1">
                                <div>Website URL</div>
                                <v-text-field
                                  class="text_field background my-2"
                                  outlined
                                  dense
                                  v-model="newContact.web_url"
                                  :rules="websiteRules"
                                ></v-text-field>
                              </div>
                            </v-flex>
                            <v-flex xs12>
                              <div align="right">
                                <v-btn
                                  depressed
                                  class="primary text-none mt-3"
                                  @click="saveContact"
                                  :disabled="!valid"
                                  :loading="loading"
                                >
                                  Save
                                  <v-icon right> mdi-content-save </v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </div>
                    </v-card-text>
                  </div>
  
                  <div v-if="activeTab == 'supplierStocks'">
                    <div v-if="supplierStockView == 'default'">
                      <v-card elevation="0" class="mt-5">
                        <v-flex xs12 class="mt-10 mb-2">
                          <div class="mx-5">
                            <v-layout row wrap>
                              <v-flex xs12 md6>
                                <div
                                    v-if="supplierAdministratorLength != 0"
                                    align="left"
                                    class="mt-1"
                                >
                                    <b class="display-1 primary--text">Total: </b
                                    >{{
                                    supplierStockPagination.total
                                        | formatNumber
                                    }}
                                </div>
                            </v-flex>
                              <v-flex xs12 md4>
                                <div class="pa-1">
                                  <v-text-field
                                    class="text_field background"
                                    outlined
                                    dense
                                    rounded
                                    label="Search"
                                    append-icon="mdi-undo-variant"
                                    @click:append="
                                      resetSupplierStockSearch()
                                    "
                                    v-on:keyup.enter="
                                      supplierStockSearch
                                    "
                                    v-model="supplierStockSearchTerm"
                                    @input="
                                      enableSupplierStockSearch()
                                    "
                                  ></v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 md2>
                                <div class="pa-1">
                                  <v-btn
                                    class="button mx-1"
                                    icon
                                    @click="supplierStockSearch"
                                    :loading="
                                      supplierStockSearchLoader
                                    "
                                    :disabled="
                                      supplierStockSearchButtonDisabled
                                    "
                                  >
                                    <v-icon class="secondary--text">mdi-magnify</v-icon>
                                  </v-btn>
                                  <v-btn
                                    class="button mx-1"
                                    icon
                                    @click="
                                      changeSupplierAdministratorView(
                                        'create'
                                      )
                                    "
                                    v-if="
                                      $can('supplier_stock_create')
                                    "
                                  >
                                    Add Stock
                                    <v-icon class="primary--text">mdi-plus-circle-outline</v-icon>
                                  </v-btn>
                                </div>
                              </v-flex>
                            </v-layout>
                          </div>
                        </v-flex>
                        <v-divider class="mt-9 mx-5"></v-divider>
                        <v-flex xs12 class="mt-5">
                          <div v-if="supplierStocks.length == 0">
                            <v-card elevation="0">
                              <v-layout row wrap>
                                <v-flex xs12 md1>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        <v-icon large class="text--text">
                                          mdi-alert-circle-outline
                                        </v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                                <v-flex xs12 md11>
                                  <v-container fill-height fluid>
                                    <v-row align="center" justify="center">
                                      <v-col class="text-center">
                                        No Supplier Stocks Found
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </div>
                          <div v-else> 
                            <div class="hidden-sm-and-down">
                                                        <v-card elevation="0" class="pa-2">
                                                            <v-simple-table>
                                                                <template v-slot:default>
                                                                    <thead class="header">
                                                                        <tr class="accent">
                                                                          <th class="text-left text--text">Part</th>
                                                                          <th class="text-left text--text">Manufacturer</th>
                                                                            <th class="text-left text--text">Stock No</th>
                                                                            <th class="text-left text--text">
                                                                                <div align="right">
                                                                                    Batch No
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-left text--text">
                                                                                <div align="right">
                                                                                    Buying (Kshs)
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-left text--text">
                                                                                <div align="right">
                                                                                    Selling (Kshs)
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-left text--text">
                                                                                <div align="right">
                                                                                    Initial Stock
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-left text--text">
                                                                                <div align="right">
                                                                                    Stock Balance
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-left text--text">
                                                                                <div align="right">
                                                                                    Manufacture Date
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-left text--text">
                                                                                <div align="right">
                                                                                    Expiry Date
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-left text--text">Date Added
                                                                            </th>
                                                                            <th class="text-left text--text">Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="item in supplierStocks" :key="item.id">
                                                                          <td>
                                                                                {{ item.part.name }}
                                                                            </td>  
                                                                            <td>
                                                                              {{ item.part.manufacturer.name  }}
                                                                              </td>
                                                                          <td>
                                                                                {{ item.stock_no }}
                                                                            </td>
                                                                         
                                                                            <td>
                                                                                <div align="right">
                                                                                    {{ item.batch_no }}
                                                                                </div>                                                                                
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    {{ item.buying_price | formatNumber  }}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    {{ item.selling_price | formatNumber  }}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    {{ item.initial_quantity | formatNumber  }}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    {{ item.current_quantity | formatNumber  }}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    {{ item.manufacture_date | moment("DD/MM/YYYY") }}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div align="right">
                                                                                    {{ item.expiry_date | moment("DD/MM/YYYY") }}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {{ item.created_at | moment("DD/MM/YYYY - hh:mm a") }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-if="item.status == 1">
                                                                                    Active
                                                                                </div>
                                                                                <div v-else>Inactive</div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </v-card>
                                                    </div>
                                                    <div class="hidden-md-and-up">
                                                        <div>
                                                            <v-layout column>
                                                                <template v-for="(
                                      supplierStock, index
                                    ) in supplierStocks">
                                                                    <div :key="index">
                                                                        <v-flex xs12 class="mb-2">
                                                                            <v-card elevation="0" style="border-radius: 25px;" outlined>
                                                                                <div class="pa-7 mt-2">
                                                                                    <v-layout column>
                                                                                        <v-flex xs12>
                                                                                            <v-layout row wrap>
                                                                                                <v-flex xs12>
                                                                                                    <div
                                                                                                        class="title primary--text">
                                                                                                        <b>{{
                                                                                                            supplierStock.part.name
                                                                                                        }}</b>
                                                                                                    </div>
                                                                                                </v-flex>
                                                                                            </v-layout>
                                                                                        </v-flex>
                                                                                        <v-divider class="mt-2"></v-divider>
                                                                                        <v-flex xs12 class="mt-1">
                                                                                            <v-layout column>
                                                                                              <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Stock No</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.stock_no }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Batch No</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.batch_no }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Buying (Kshs)</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.buying_price | formatNumber }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Selling (Kshs)</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.selling_price | formatNumber }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Initial Stock</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.initial_quantity | formatNumber }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Stock Balance</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.current_quantity | formatNumber }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Manufacture Date</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.manufacture_date }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Expiry Date</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.expiry_date }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>


                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Status</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            <div v-if="supplierStock.status ==
                                                                                                                1
                                                                                                                ">
                                                                                                                Active
                                                                                                            </div>
                                                                                                            <div v-else>
                                                                                                                Inactive
                                                                                                            </div>
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                                <v-flex xs12 class="mt-5">
                                                                                                    <v-layout row wrap>
                                                                                                        <v-flex xs4>
                                                                                                            <b>Date
                                                                                                                Created</b>
                                                                                                        </v-flex>
                                                                                                        <v-flex xs8>
                                                                                                            {{ supplierStock.created_at | moment("DD/MM/YYYY") }}
                                                                                                        </v-flex>
                                                                                                    </v-layout>
                                                                                                </v-flex>
                                                                                            </v-layout>
                                                                                        </v-flex>
                                                                                    </v-layout>
                                                                                </div>
                                                                            </v-card>
                                                                        </v-flex>
                                                                    </div>
                                                                </template>
                                                            </v-layout>
                                                        </div>
                                                    </div>
                          </div>
                        </v-flex>
                      </v-card>
                      <v-layout column class="mt-5">
                        <v-flex xs12>
                          <div align="left">
                            <v-pagination
                              v-if="length != 0"
                              :length="supplierAdministratorLength"
                              total-visible="10"
                              v-model="
                                supplierStockPagination.current_page
                              "
                              @input="changeSupplierStockPage()"
                              circle
                            >
                            </v-pagination>
                          </div>

                        </v-flex>
                        
                      </v-layout>
                    </div>
                    <div v-if="supplierStockView == 'create'">
                      <v-flex class="mt-5">
                        <v-card-title class="tableHeader text--text">
                          <v-spacer></v-spacer>
                          <div v-if="newStock.id == null">
                            Add Supplier Stock
                          </div>
                          <div v-else>Edit Supplier Stock</div>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            @click="
                              changeSupplierAdministratorView('default')
                            "
                          >
                            <v-icon class="text--text"> mdi-close </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            v-model="valid"
                            ref="supplierAdministratorForm"
                            lazy-validation
                          >
                            <v-layout row wrap class="mt-5">
                              <v-flex xs12 md4>
                                <div class="pa-3">
                                  <div>
                                    User Code<span class="error--text">*</span>
                                  </div>
                                  <v-autocomplete
                                    v-model="newStock.user_id"
                                    :loading="loading"
                                    :items="items"
                                    :search-input.sync="searchStocks"
                                    item-value="id"
                                    item-text="code"
                                    cache-items
                                    class="text_field background my-2"
                                    flat
                                    hide-no-data
                                    hide-details
                                    outlined
                                    dense
                                    :rules="inputRules"
                                  >
                                    <template v-slot:selection="{ item }">
                                      {{ getText(item) }}
                                    </template>
                                    <template v-slot:item="{ item }">
                                      {{ getText(item) }}
                                    </template>
                                  </v-autocomplete>
                                </div>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            depressed
                            class="primary text-none mt-3"
                            @click="saveSupplierAdministrator"
                            :disabled="!valid"
                            :loading="loading"
                          >
                            Save
                            <v-icon right> mdi-content-save </v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-layout>
      </div>
      <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
  </template>
  <style>
  .ck-editor__editable {
    min-height: 200px;
  }
  </style>
  <script>
  import Vue from "vue";
  import apiCall from "@/utils/api";
  import { mapGetters, mapState, mapActions } from "vuex";
  import logData from "@/views/accessControl/logData";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  
  Vue.use(CKEditor);
  
  export default {
    components: {
      logData,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
  
        editor: ClassicEditor,
        editorConfig: {},
  
        importDialog: false,
        activeTab: "details",
        
        view: "default",
        supplierStockView: "default",
  
        contactView: "default",
        loading: false,
        supplierStockLoader: false,
  
        activateLoading: false,
        deactivateLoading: false,
  
        valid: true,
        message: "",
        color: "",
        delete: false,
        activate: false,
        confirm: false,
        tab: null,
        
        supplierTab: null,
        supplierStockIndex: null,
        supplierIndex: null,
  
        snackbar: false,
        inputRules: [(v) => !!v || "Input is required"],
  
        emailRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
              v
            ) ||
            "E-mail must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
        websiteRules: [
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#:$%&'*+/=?^_~-]+[a-zA-Z]{2,3}$/.test(v) ||
            "URL must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
  
        items: [],
        searchStocks: null,
  
        searchTerm: null,
        supplierStockSearchTerm: null,
  
        searchLoader: false,
        searchButtonDisabled: false,
        supplierStockSearchButtonDisabled: false,
  
  
        filteredSuppliers: false,
        filteredSupplierStocks: false,
  
        years: [],
        newSupplier: {
          id: null,
          name: null,
          supplier_no: null,
          county_id: null,
          year: null,
          physical_address: null,
        },
  
        newContact: {
          id: null,
          supplier_id: null,
          primary_email: null,
          secondary_email: null,
          primary_phone: null,
          secondary_phone: null,
          web_url: null,
        },
  
        newStock: {
          id: null,
          supplier_id: null,
          user_id: null,
        },
  
        assignedSupplier: null,
        formData: null,
  
        coverImageDialog: false,
        imageDialog: false,
        imageLoading: false,
        fileRules: [(v) => !!v || "Kindly Select a File"],
        imageFile: null,
        imageValid: true,
  
        searchTerm: null,
        searchLoader: false,
        supplierStockSearchLoader: false,
  
        searchButtonDisabled: false,
  
        filteredSuppliers: [],
        supplierStocks: [],
  
        supplierStockPagination: {
          search: " ",
          current_page: 1,
          per_page: 0,
          total: 0,
          visible: 10,
        },

        
        logData: {
          message: null,
          model: null,
        },
      };
    },
    watch: {
      searchStocks(val) {
        val && val !== this.newStock.user_id && this.findStock(val);
      },
    },
    created() {
      if (this.$can("supplier_view")) {
        this.startSupplierLoader();
        this.fetchSuppliers(this.supplierPagination.current_page);
        this.getFormData();
        this.populateYears();
      }
    },
    methods: {
      ...mapActions([
        "fetchSuppliers",
        "startSupplierLoader",
        "stopSupplierLoader",
        "filterSuppliers",
  
        "filterLogData",
        "openLogDataModel",
      ]),
      populateYears() {
        var max = new Date().getFullYear();
        var min = max - 100;
        var years = [];
  
        for (var i = max; i >= min; i--) {
          years.push(i);
        }
        this.years = years;
      },
      findStock(v) {
        this.loading = true;
        
        setTimeout(() => {
          apiCall({
            url: "/api/user?type=search&search=" + this.searchStocks,
            method: "GET",
          })
            .then((resp) => {
              this.items = resp.data;
            })
            .catch((error) => {
              this.loading = false;
            });
  
          this.loading = false;
        }, 3000);
      },
  
      showSupplierStock(user) {
        this.$router.push("/user/" + user.code);
      },
     
      enableSearch() {
        this.searchButtonDisabled = false;
      },
      
  
      enableSupplierStockSearch() {
        this.supplierStockSearchButtonDisabled = false;
      },
      
     
      resetSearch() {
        this.searchTerm = null;
        this.filteredSuppliers = true;
        this.startSupplierLoader();
        this.fetchSuppliers(1);
      },    
  
      resetSupplierStockSearch() {
        this.supplierStockSearchTerm = null;
        this.filteredSupplierStocks = false;
        this.supplierStockLoader = false;
        this.changeSupplierStockPage();
      },

      changeSupplierStockPage() {
        this.supplierStockLoader = true;
        if (this.filteredSupplierStocks == true) {
          this.supplierStockSearchButtonDisabled = false;
          apiCall({
            url:
              "/api/supplier-stock?supplier_id=" +
              this.assignedSupplier.id +
              "&page=" +
              this.supplierStockPagination.current_page +
              "&type=search&search=" +
              this.supplierStockSearchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.supplierStockLoader = false;
  
              this.supplierStocks = resp.data;
              this.supplierStockPagination.current_page =
                resp.current_page;
              this.supplierStockPagination.total = resp.total;
              this.supplierStockPagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.supplierStockLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          apiCall({
            url:
              "/api/supplier-stock?supplier_id=" +
              this.assignedSupplier.id +
              "&page=" +
              this.supplierStockPagination.current_page,
            method: "GET",
          })
            .then((resp) => {
              this.supplierStockLoader = false;
  
              this.supplierStocks = resp.data;
              this.supplierStockPagination.current_page =
                resp.current_page;
              this.supplierStockPagination.total = resp.total;
              this.supplierStockPagination.per_page = resp.per_page;
            })
            .catch((error) => {
              console.log(error);
              this.supplierStockLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      
      getText(item) {
        return (
          item.first_name + " " + item.middle_name + " " + item.last_name + " #" + item.code
        );
      },
      showSupplier(supplier) {
        this.assignedSupplier = supplier;
        this.newContact.supplier_id = supplier.id;

        this.changeSupplierStockPage();
        this.changeView("show");
      },
  
      editSupplier(supplier) {
        this.newSupplier.id = supplier.id;
        this.newSupplier.name = supplier.name;
        this.newSupplier.supplier_no =
          supplier.supplier_no;
        this.newSupplier.county_id = supplier.county_id;
        this.newSupplier.brief = supplier.brief;
        this.newSupplier.description = supplier.description;
        this.newSupplier.physical_address = supplier.physical_address;
  
        console.log("this.newSupplier", this.newSupplier)
  
        this.changeView("create");
      },
      editContact(contact) {
        this.newContact.id = contact.id;
        this.newContact.contact_person = contact.contact_person;
        this.newContact.primary_email = contact.primary_email;
        this.newContact.secondary_email = contact.secondary_email;
        this.newContact.primary_phone = contact.primary_phone;
        this.newContact.secondary_phone = contact.secondary_phone;
        this.newContact.web_url = contact.web_url;
        this.newContact.fb_url = contact.fb_url;
        this.newContact.insta_url = contact.insta_url;
        this.newContact.twitter_url = contact.twitter_url;
        this.newContact.visible = contact.visible;
        this.contactView = "create";
      },
  
      uploadImage() {
        if (this.$refs.imageForm.validate()) {
          this.imageLoading = true;
          let formData = new FormData();
  
          // files
  
          formData.append("files", this.imageFile, this.imageFile.name);
          formData.append("id", this.assignedSupplier.id);
  
          apiCall({
            url: "/api/supplier?type=image",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.snackbar = true;
              this.message = "Logo Uploaded Successfully";
              this.color = "success";
              this.fetchSuppliers(this.supplierPagination.current_page);
              this.imageLoading = false;
              this.imageDialog = false;
              this.imageFile = null;
              this.assignedSupplier.logo = resp.logo;
              // this.changeView('default')
            })
            .catch((error) => {
              this.imageLoading = false;
            });
        }
      },
      uploadCoverImage() {
        if (this.$refs.imageForm.validate()) {
          this.imageLoading = true;
          let formData = new FormData();
  
          // files
  
          formData.append("files", this.imageFile, this.imageFile.name);
          formData.append("id", this.assignedSupplier.id);
  
          apiCall({
            url: "/api/supplier?type=coverImage",
            data: formData,
            method: "POST",
          })
            .then((resp) => {
              this.snackbar = true;
              this.message = "Cover Image Uploaded Successfully";
              this.color = "success";
              this.fetchSuppliers(this.supplierPagination.current_page);
              this.imageLoading = false;
              this.coverImageDialog = false;
              this.imageFile = null;
              this.assignedSupplier.cover_image = resp.cover_image;
              // this.changeView('default')
            })
            .catch((error) => {
              this.imageLoading = false;
            });
        }
      },
      save() {
        if (this.$refs.form.validate()) {
          if (this.newSupplier.id != null) {
            confirm("Are you sure you want to update supplier?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/supplier/" + this.newSupplier.id,
                data: this.newSupplier,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Supplier Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchSuppliers(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are you sure you want to create supplier?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/supplier",
                data: this.newSupplier,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Supplier Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchSuppliers(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("default");
                  this.fetchSuppliers(1);
                });
            }
          }
        }
      },
      saveContact() {
        if (this.$refs.contactForm.validate()) {
          if (this.newContact.id != null) {
            confirm("Are you sure you want to update contact information?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/supplier-contact/" + this.newContact.id,
                data: this.newContact,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "Contact Information Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.$nextTick(() => {
                    this.assignedSupplier.supplier_contact = resp;
                  });
  
                  this.changeContactView("default");
  
                  this.fetchSuppliers(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are you sure you want to create contact information?") &&
              (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/supplier-contact",
                data: this.newContact,
                method: "POST",
              })
                .then((resp) => {
                  this.message = "Contact Information Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeContactView("default");
                  this.assignedSupplier.supplier_contact = resp;
                  this.fetchSuppliers(1);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.fetchSuppliers(1);
                });
            }
          }
        }
      },
  
      saveSupplierAdministrator() {
        if (this.$refs.supplierAdministratorForm.validate()) {
          confirm("Are you sure you want to add supplier stock?") &&
            (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            this.newStock.supplier_id = this.assignedSupplier.id;
            apiCall({
              url: "/api/supplier-stock",
              data: this.newStock,
              method: "POST",
            })
              .then((resp) => {
                this.message = "Supplier Stock Added Successfully";
                this.color = "success";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.newStock.user_id = null;
                this.changeSupplierAdministratorView("default");
                this.changeSupplierStockPage();
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        }
      },
  
      search() {
        if (this.searchTerm == null) {
          this.searchButtonDisabled = true;
        } else {
          this.searchLoader = true;
          this.searchButtonDisabled = false;
          this.startSupplierLoader();
          apiCall({
            url: "/api/supplier?type=search&search=" + this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterSuppliers(resp);
              this.searchLoader = false;
              this.stopSupplierLoader();
              this.filteredSuppliers = true;
            })
            .catch((error) => {
              console.log(error);
              this.searchLoader = false;
              this.stopSupplierLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      
  
      supplierStockSearch() {
        if (this.supplierStockSearchTerm == null) {
          this.supplierStockSearchButtonDisabled = true;
        } else {
          this.supplierStockSearchLoader = true;
          this.supplierStockSearchButtonDisabled = false;
          this.startSupplierLoader();
          apiCall({
            url:
              "/api/supplier-stock?type=search&search=" +
              this.supplierStockSearchTerm +
              "&supplier_id=" +
              this.assignedSupplier.id,
            method: "GET",
          })
            .then((resp) => {
              this.supplierStocks = resp.data;
              this.supplierStockPagination.current_page =
                resp.current_page;
              this.supplierStockPagination.total = resp.total;
              this.supplierStockPagination.per_page = resp.per_page;
  
              this.supplierStockSearchLoader = false;
              this.filteredSupplierStocks = true;
            })
            .catch((error) => {
              this.supplierStockSearchLoader = false;
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              console.log(error);
            });
        }
      },
      activateSupplier(item) {
        confirm("Are You Sure You Want to Activate Supplier?") &&
          (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.supplierIndex = item.id;
          this.newSupplier.id = item.id;
          apiCall({
            url: "/api/supplier?type=activate",
            data: this.newSupplier,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Supplier Account Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.supplierIndex = null;
              this.newSupplier.id = null;
              this.fetchSuppliers(this.supplierPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.supplierIndex = null;
              this.newSupplier.id = null;
              console.log(error);
            });
        }
      },
      deactivateSupplier(item) {
        confirm("Are You Sure You Want to Deactivate Supplier?") &&
          (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.supplierIndex = item.id;
          this.newSupplier.id = item.id;
          apiCall({
            url: "/api/supplier?type=deactivate",
            data: this.newSupplier,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Supplier Account Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.supplierIndex = null;
              this.newSupplier.id = null;
              this.fetchSuppliers(this.supplierPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.supplierIndex = null;
              this.newSupplier.id = null;
              console.log(error);
            });
        }
      },
     
      activateSupplierStock(item) {
        confirm(
          "Are You Sure You Want to Activate Supplier Stock?"
        ) && (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.supplierStockIndex = item.id;
          this.newStock.id = item.id;
          apiCall({
            url: "/api/supplier-stock?type=activate",
            data: this.newStock,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Supplier Stock Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.supplierStockIndex = null;
              this.newStock.id = null;
              this.changeSupplierStockPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.supplierStockIndex = null;
              this.newStock.id = null;
              console.log(error);
            });
        }
      },
  
      deactivateSupplierAdministrator(item) {
        confirm(
          "Are You Sure You Want to Deactivate Supplier Stock?"
        ) && (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.supplierStockIndex = item.id;
          this.newStock.id = item.id;
          apiCall({
            url: "/api/supplier-stock?type=deactivate",
            data: this.newStock,
            method: "POST",
          })
            .then((resp) => {
              this.message = "Supplier Stock Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.supplierStockIndex = null;
              this.newStock.id = null;
              this.changeSupplierStockPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.supplierStockIndex = null;
              this.newStock.id = null;
              console.log(error);
            });
        }
      },
      
      changeView(view) {
        if (view == "default") {
          this.newSupplier.id = null;
          this.newSupplier.name = null;
          this.newSupplier.supplier_no = null;
          this.newSupplier.county_id = null;
          this.newSupplier.description = null;
          this.newSupplier.physical_address = null;
        } else if (view == "create") {
          this.getFormData();
        } else if (view == "show") {
        }
        this.view = view;
      },
  
      changeContactView(view) {
        if (view == "default") {
          this.newContact.id = null;
          this.newContact.supplier_id = null;
          this.newContact.primary_email = null;
          this.newContact.secondary_email = null;
          this.newContact.primary_phone = null;
          this.newContact.secondary_phone = null;          
          this.newContact.web_url = null;
        } else if (view == "create") {
          //this.getFormData();
        } else if (view == "show") {
        }
        this.contactView = view;
      },
     
  
      changeSupplierAdministratorView(view) {
        if (view == "default") {
          this.newStock.id = null;
          this.newStock.supplier_id = null;
          this.newStock.user_id = null;
        } else if (view == "create") {
        } else if (view == "show") {
        }
        this.supplierStockView = view;
      },
      
      changePage() {
        this.startSupplierLoader();
        if (this.filteredSuppliers == true) {
          this.searchButtonDisabled = false;
          apiCall({
            url:
              "/api/supplier?page=" +
              this.supplierPagination.current_page +
              "&type=search&search=" +
              this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterSuppliers(resp);
              this.stopSupplierLoader();
            })
            .catch((error) => {
              console.log(error);
              this.stopSupplierLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.fetchSuppliers(this.supplierPagination.current_page);
        }
      },
      deleteSupplier(item) {
        confirm("Are You Sure You Want to Delete Supplier") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.supplierIndex = item.id;
          apiCall({ url: "/api/supplier/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "Supplier Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.supplierIndex = null;
              this.fetchSuppliers(this.supplierPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.supplierIndex = null;
              console.log(error);
            });
        }
      },
      deleteContact(item) {
        confirm("Are You Sure You Want to Delete Contact Information?") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          apiCall({
            url: "/api/supplier-contact/" + item.id,
            method: "DELETE",
          })
            .then((resp) => {
              this.message = "Contact Information Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.$nextTick(() => {
                this.assignedSupplier.supplier_contact = null;
              });
              this.fetchSuppliers(this.supplierPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.supplierIndex = null;
              console.log(error);
            });
        }
      },
  
      deleteSupplierStock(){
        confirm("Are You Sure You Want to Remove Stock?") &&
          (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.supplierStockIndex = item.id;
          apiCall({
            url: "/api/supplier-stock/" + item.id,
            method: "DELETE",
          })
            .then((resp) => {
              this.message = "Stock Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.supplierStockIndex = null;
              this.changeSupplierStockPage();
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.supplierStockIndex = null;
              console.log(error);
            });
        }
      },
      
      getFormData() {
        apiCall({
          url: "/api/supplier?type=formData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
          });
      },
      viewLog(data) {
        this.logData.message = data.id;
        this.logData.model = "App\\Models\\Supplier";
        this.filterLogData(this.logData);
        this.openLogDataModel();
      },
    },
    computed: {
      ...mapGetters([
        "suppliers",
        "supplierPagination",
        "supplierLoader",
        "logDataModel"
      ]),
      length: function () {
        return Math.ceil(
          this.supplierPagination.total / this.supplierPagination.per_page
        );
      },
      supplierAdministratorLength: function () {
        return Math.ceil(
          this.supplierStockPagination.total /
            this.supplierStockPagination.per_page
        );
      },
    
      dateToday() {
        var today = new Date();
  
        var timestamp =
          today.getFullYear() +
          "-" +
          this.months[today.getMonth()] +
          "-" +
          ("0" + today.getDate()).slice(-2);
        return timestamp;
      },
    },
  };
  </script>
  