<template>
    <div class="dashboard">
        <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
            <span>{{ message }}</span>
        </v-snackbar>
        <v-progress-linear v-if="partLearningAreaLoader" height="2" indeterminate color="primary"></v-progress-linear>
        <div class="pa-5">
            <v-layout column>
                <v-flex xs12 class="mt-9">
                    <div v-if="partLearningAreas.length == 0">
                        <v-card elevation="0">
                            <v-container fill-height fluid>
                                <v-row align="center" justify="center">
                                    <v-col class="text-center"> No Product Found </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-layout row wrap>
                            <template v-for="(partLearningArea, index) in partLearningAreas">
                                <v-flex xs12 md3 :key="index">
                                    <div class="mr-1 mb-1">
                                        <v-card elevation="0" style="border-radius: 25px;" outlined :loading="loading" class="d-flex flex-column mb-5 mx-1" height="410">
                                        <template slot="progress">
                                            <v-progress-linear color="deep-purple" height="10"
                                                indeterminate></v-progress-linear>
                                        </template>

                                        <v-img v-if="partLearningArea.image==null" class="background" contain style="border-radius: 25px;" max-height="180" src="img/book-silhouette-vector-14.png">
                                            <div align="right" class="mt-1 mr-1">
                                                <v-btn
                                                    v-if="partLearningArea.favourite==false"
                                                    icon
                                                    @click="addToWishlist(partLearningArea)"
                                                    :loading="
                                                    wishlistLoading && partLearningAreaIndex == partLearningArea.id
                                                    "
                                                >
                                                    <v-icon small>mdi-heart</v-icon>
                                                </v-btn>
                                                </div>
                                                <div align="right" class="mt-1 mr-1">
                                                <v-btn
                                                    v-if="partLearningArea.favourite==true"
                                                    icon
                                                    @click="removeFromWishlist(partLearningArea)"
                                                    :loading="
                                                    wishlistLoading && partLearningAreaIndex == partLearningArea.id
                                                    "
                                                >
                                                    <v-icon class="red--text">mdi-heart</v-icon>
                                                </v-btn>
                                                </div>
                                        </v-img>
                                        <v-img
                                            max-height="180"
                                            contain
                                            v-else
                                            :src="path+'/storage/part_pictures/'+partLearningArea.image"
                                            style="border-radius: 25px;"
                                            class="background"
                                            >
                                            <div align="right" class="mt-1 mr-1">
                                                <v-btn
                                                    v-if="partLearningArea.favourite==false"
                                                    icon
                                                    @click="addToWishlist(partLearningArea)"
                                                    :loading="
                                                    wishlistLoading && partLearningAreaIndex == partLearningArea.id
                                                    "
                                                >
                                                    <v-icon small>mdi-heart</v-icon>
                                                </v-btn>
                                                </div>
                                                <div align="right" class="mt-1 mr-1">
                                                <v-btn
                                                    v-if="partLearningArea.favourite==true"
                                                    icon
                                                    @click="removeFromWishlist(partLearningArea)"
                                                    :loading="
                                                    wishlistLoading && partLearningAreaIndex == partLearningArea.id
                                                    "
                                                >
                                                    <v-icon class="red--text">mdi-heart</v-icon>
                                                </v-btn>
                                                </div>
                                            </v-img>

                                        <div>
                                            <router-link :to="'/product/' + partLearningArea.slug"
                                                style="text-decoration: none !important">
                                                <div align="center" class="subtitle mt-5">
                                                    <v-clamp autoresize :max-lines="2">
                                                        {{ partLearningArea.name }}
                                                    </v-clamp>
                                                </div>
                                            </router-link>

                                            
                                            </div>
                                            <!-- <div align="center" class="caption">
                                                <b>By</b> <i>{{ partLearningArea.manufacturer_name }}</i>
                                            </div> -->
                                            <div align="center" class="mt-5 primary--text">
                                                Kshs {{ partLearningArea.price }}
                                            </div>
                                        <div align="center" class="mt-9 red--text" v-if="partLearningArea.quantity==0">
                                            Out of Stock
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-divider class="mx-4"></v-divider>
                                        <div class="my-2 mx-2">
                                            <v-btn v-if="partLearningArea.quantity!=0" rounded block text class="accent--text text-none"
                                                @click="addToCart(partLearningArea)" :loading="cartLoading && partLearningAreaIndex == partLearningArea.id
                                                    ">
                                                Add to Cart
                                                <v-icon small right>
                                                    mdi-cart
                                                </v-icon>
                                            </v-btn>
                                            <v-btn v-if="partLearningArea.quantity==0" disabled rounded block text class="accent--text text-none">
                                                Add to Cart
                                                <v-icon small right>
                                                    mdi-cart
                                                </v-icon>
                                            </v-btn>
                                            
                                        </div>
                                    </v-card>
                                    </div>
                                </v-flex>
                            </template>
                        </v-layout>
                    </div>
                </v-flex>
                <v-flex xs12 class="my-10">
                    <v-pagination v-if="length != 0" :length="length" total-visible="10" circle
                        v-model="partLearningAreaPagination.current_page" @input="changePage()">
                    </v-pagination>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import AuthPopUp from "@/components/auth/AuthPopUp";
import { mapGetters, mapActions } from "vuex";
export default {
    components: {},
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            loading: false,
            valid: true,
            message: "",
            color: "",
            delete: false,
            confirm: false,
            dialog: false,
            snackbar: false,
            cartLoading: false,
            wishlistLoading: false,

            searchTerm: null,
            filteredParts: false,
            wishlistIndex: null,
            partLearningAreaIndex: null,            
        };
    },
    watch:{
        $route (to, from){
            this.fetchPartLearningCategory();
        }
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        window.scrollTo(0, 0);

        this.fetchPartLearningCategory();
    },
    methods: {
        ...mapActions([            
            "startPartLearningAreaLoader",
            "stopPartLearningAreaLoader",
            "filterPartLearningAreas",
            "fetchCarts",
            "fetchWishlists"
        ]),

        fetchPartLearningCategory(){
            this.startPartLearningAreaLoader();
            apiCall({
                url:
                    "/api/part?page=1&type=learningArea&learningArea=" +
                    this.$route.params.learningArea,
                method: "GET",
            })
                .then((resp) => {
                    this.filterPartLearningAreas(resp);
                    this.stopPartLearningAreaLoader();
                })
                .catch((error) => {
                    console.log(error.response);
                    this.stopPartLearningAreaLoader();
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                });            
        },
        
        changePage() {
            window.scrollTo(0, 0);
            this.startPartLearningAreaLoader();
            if (this.filteredParts == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/part?page=" +
                        this.partLearningAreaPagination.current_page +
                        "&type=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterPartLearningAreas(resp);
                        this.stopPartLearningAreaLoader();
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.stopPartLearningAreaLoader();
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.startPartLearningAreaLoader();
                apiCall({
                    url:
                        "/api/part?page="+this.partLearningAreaPagination.current_page+"&type=learningArea&learningArea=" +
                        this.$route.params.learningArea,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterPartLearningAreas(resp);
                        this.stopPartLearningAreaLoader();
                    })
                    .catch((error) => {
                        console.log(error.response);
                        this.stopPartLearningAreaLoader();
                        this.message = "anErrorOccurredMsg";
                        this.color = "error";
                        this.snackbar = true;
                    });  
            }
        },
        addToWishlist(item) {
            this.wishlistLoading = true;
            this.partLearningAreaIndex = item.id;
            apiCall({
                url: "/api/wishlist",
                data: item,
                method: "POST",
            })
                .then((resp) => {
                if (resp.status == 200) {
                    this.message = resp.message;
                    this.color = "success";
                    this.wishlistLoading = false;
                    this.snackbar = true;

                    this.changePage();
                    this.fetchWishlists(1)
                } else if (resp.status == 409) {
                    this.message = resp.message;
                    this.color = "error";
                    this.wishlistLoading = false;
                    this.snackbar = true;
                }
                })
                .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.wishlistLoading = false;
                this.snackbar = true;
                });
        },
        removeFromWishlist(item) {
            this.wishlistLoading = true;
            this.partLearningAreaIndex = item.id;
            apiCall({ url: "/api/wishlist/" + item.id, method: "DELETE" })
                .then((resp) => {
                    this.message = "Item removed from wishlist successfully";
                    this.color = "success";
                    this.snackbar = true;
                    this.wishlistLoading = false;
                    this.delete = false;
                    this.partLearningAreaIndex = null;
                    this.changePage();
                    this.fetchWishlists(1)
                })
                .catch((error) => {
                    this.message = "anErrorOccurredMsg";
                    this.color = "error";
                    this.snackbar = true;
                    this.wishlistLoading = false;
                    this.delete = false;
                    this.partLearningAreaIndex = null;
                    console.log(error.response);
                });
        },
        addToCart(item) {
            confirm("Are you sure you want to add item to cart?") &&
                (this.confirm = true);
            if (this.confirm) {
                this.partLearningAreaIndex = item.id;
                this.cartLoading = true;
                apiCall({
                    url: "/api/cart",
                    data: item,
                    method: "POST",
                })
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.message = resp.message;
                            this.color = "success";
                            this.cartLoading = false;
                            this.snackbar = true;

                            this.partLearningAreaIndex = null;
                            this.confirm = false
                            this.fetchCarts(1);
                        } else if (resp.status == 409) {
                            this.message = resp.message;
                            this.color = "error";
                            this.cartLoading = false;
                            this.snackbar = true;

                            this.partLearningAreaIndex = null;
                            this.confirm = false
                        }

                    })
                    .catch((error) => {
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.cartLoading = false;
                        this.snackbar = true;
                    });
            }
        },
    },
    computed: {
        ...mapGetters(["partLearningAreas", "partLearningAreaPagination", "partLearningAreaLoader"]),
        length: function () {
            return Math.ceil(
                this.partLearningAreaPagination.total / this.partLearningAreaPagination.per_page
            );
        },
    },
};
</script>