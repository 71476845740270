import apiCall from '@/utils/api'

const state = {
	manufacturers: [],
	allManufacturers: [],
    manufacturerPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    manufacturerLoader: false
};

const getters = {
	manufacturers: (state) => state.manufacturers,
	allManufacturers: (state) => state.allManufacturers,
    manufacturerLoader: (state) => state.manufacturerLoader,
    manufacturerPagination: (state) => state.manufacturerPagination
}

const actions = {
	async fetchAllManufacturers({commit}, page) {
		const response = await apiCall({url: `/api/manufacturer?type=all`, method: 'GET' });
		commit('setAllManufacturers', response)
        commit('stopLoader', response)
	},
    async fetchManufacturers({commit}, page) {
		const response = await apiCall({url: `/api/manufacturer?page=${page}`, method: 'GET' });
		commit('setManufacturers', response)
        commit('stopLoader', response)
	},
    async filterManufacturers({commit, state},resp){
		commit('setManufacturers', resp)
	},
	async startManufacturerLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopManufacturerLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setManufacturers: (state, manufacturers) => {
		state.manufacturers = manufacturers.data
		state.manufacturerPagination.current_page = manufacturers.current_page
		state.manufacturerPagination.total = manufacturers.total
		state.manufacturerPagination.per_page = manufacturers.per_page
	},
	setAllManufacturers: (state, manufacturers) => {
		state.allManufacturers = manufacturers
	},
    startLoader: (state) => state.manufacturerLoader = true,
	stopLoader: (state) => state.manufacturerLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
