import Vue from 'vue'
import Vuex from 'vuex'

import notifications from './modules/notifications'

import role from './modules/role'
import users from './modules/users'
import student from './modules/student'
import permission from './modules/permission'
import user from './modules/user'
import auth from './modules/auth'
import log from './modules/log'
import logData from './modules/logData'

import abilities from './modules/abilities'
import loginCard from './modules/loginCard'
import darkMode from './modules/darkMode'

import contactUs from './modules/contactUs'

import cart from './modules/cart'
import wishlist from './modules/wishlist'
import sale from './modules/sale'
import salePartStock from './modules/salePartStock'

import wallet from './modules/wallet'
import walletLog from './modules/walletLog'

import part from './modules/part'
import studentLevel from './modules/studentLevel'
import stock from './modules/stock'
import level from './modules/level'
import ad from './modules/ad'
import category from './modules/category'
import learningArea from './modules/learningArea'
import manufacturer from './modules/manufacturer'
import supplier from './modules/supplier'
import partCategory from './modules/partCategory'
import levelCategory from './modules/levelCategory'
import learningAreaCategory from './modules/learningAreaCategory'

import VuexPersist from 'vuex-persist'
import { abilityPlugin, ability as appAbility } from './ability'

Vue.use(Vuex)

export const ability = appAbility

const vuexLocalStorage = new VuexPersist({
  key: 'strathmore-bookshop-front', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
    abilityPlugin
  ],

  modules: {
    notifications,

    role,
    users,
    student,
    user,
    auth,
    permission,
    log,
    logData,
    abilities,
    loginCard,
    darkMode,

    contactUs,

    cart,
    wishlist,
    sale,
    salePartStock,

    wallet,
    walletLog,

    part,
    studentLevel,
    stock,
    level,
    ad,
    category,
    learningArea,
    manufacturer,
    supplier,

    partCategory,
    levelCategory,
    learningAreaCategory

  },
  strict: false,
})