<template>
    <div class="publicSales">
        <div class="pa-5">
            <v-layout column>
                <v-card elevation="0" class="mt-5">
                    <v-progress-linear v-if="publicSaleLoader" height="1" indeterminate color="primary">
                    </v-progress-linear>
                    <v-flex xs12 class="mt-5">
                        <div v-if="publicSales.length == 0">
                            <v-card elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md1>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    <v-icon large class="primary--text">
                                                        mdi-alert-circle-outline
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                    <v-flex xs12 md11>
                                        <v-container fill-height fluid>
                                            <v-row align="center" justify="center">
                                                <v-col class="text-center">
                                                    No Sale Records Found
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </div>
                        <div v-else>
                            <div v-if="view == 'default'">
                                <div>
                                    <v-layout column>
                                        <template v-for="(publicSale, index) in publicSales">
                                            <div :key="index">
                                                <v-flex xs12>
                                                    <v-card elevation="0" class="mb-2" style="border-radius: 25px;" outlined>
                                                        <v-card-text>
                                                            <v-layout row wrap>
                                                                <v-flex xs12 md4>
                                                                    <div>
                                                                        <v-carousel height="300" cycle interval="10000" hide-delimiter-background show-arrows-on-hover
                                                                            style="border-radius: 25px;">
                                                                            <v-carousel-item v-for="(sale_part, i) in publicSale.sale_parts" :key="i">
                                                                                <v-img v-if="sale_part.part.image == null" class="background" contain
                                                                                    style="border-radius: 25px;" max-height="300" 
                                                                                    src="img/book-silhouette-vector-14.png">
                                                                                </v-img>
                                                                                <v-img max-height="300"  contain v-else
                                                                                    :src="path + '/storage/part_pictures/' + sale_part.part.image"
                                                                                    style="border-radius: 25px;" class="background">
                                                                                </v-img>
                                                                            </v-carousel-item>
                                                                        </v-carousel>
                                                                    </div>
                                                                </v-flex>
                                                                <v-flex xs12 md8>
                                                                    <div class="mx-5 mb-5">
                                                                        <v-layout column>
                                                                            <v-flex xs12 class="mt-1">
                                                                                <v-layout column>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Sale No</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                {{ publicSale.sale_no }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Sale Code</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                {{ publicSale.sale_code }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Student</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                {{ publicSale.sale_student.student.first_name }} {{ publicSale.sale_student.student.middle_name }} {{ publicSale.sale_student.student.last_name }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Transaction Code(s)</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                <div v-if="publicSale.cash_payment!=null">
                                                                                                    {{ publicSale.cash_payment.cash_no }}
                                                                                                </div>
                                                                                                <div v-if="publicSale.credit!=null">
                                                                                                    {{ publicSale.credit.credit_no }}
                                                                                                </div>
                                                                                                <div v-if="publicSale.mpesa!=null">
                                                                                                    {{ publicSale.mpesa?.mpesa_callback_metadata.MpesaReceiptNumber }}
                                                                                                </div>
                                                                                                <div v-if="publicSale.sale_wallet_logs.length != 0">
                                                                                                    <template v-for="(sale_wallet_log, index) in publicSale.sale_wallet_logs">
                                                                                                    <div>
                                                                                                        {{ sale_wallet_log.wallet_log.transaction_number }}
                                                                                                    </div>
                                                                                                    </template>
                                                                                                </div>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Item(s)</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                {{ publicSale.sale_parts.length }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Total</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                Kshs {{ publicSale.total }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Status</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                {{ publicSale.status.name }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-5">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs4>
                                                                                                <b>Date Created</b>
                                                                                            </v-flex>
                                                                                            <v-flex xs8>
                                                                                                {{
                                                                                                    publicSale.created_at
                                                                                                    | moment("DD/MM/YYYY - hh:mm a")
                                                                                                }}
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                    </v-flex>
                                                                                    <v-flex xs12 class="mt-10">
                                                                                        <v-layout row wrap>
                                                                                            <v-flex xs12 md6>
                                                                                                <div align="center">
                                                                                                    <v-btn @click="showSale(publicSale)" depressed class="text-none primary white--text mt-1">
                                                                                                        View Sale
                                                                                                        <v-icon right>
                                                                                                            mdi-eye-circle
                                                                                                        </v-icon>
                                                                                                    </v-btn>
                                                                                                </div>
                                                                                            </v-flex>
                                                                                            <v-flex xs12 md6>
                                                                                                <div align="center">
                                                                                                    <v-btn @click="downloadReceipt(publicSale, index)" depressed class="text-none pdf white--text mt-1" :loading="downloadLoading && downloadIndex == index">
                                                                                                        Download Receipt
                                                                                                        <v-icon right>
                                                                                                            mdi-file-download
                                                                                                        </v-icon>
                                                                                                    </v-btn>
                                                                                                </div>
                                                                                            </v-flex>
                                                                                        </v-layout>
                                                                                        
                                                                                    </v-flex>
                                                                                </v-layout>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </div>
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-flex>
                                            </div>
                                        </template>
                                    </v-layout>
                                </div>
                                <div align="left" class="mt-5">
                                    <v-pagination v-if="length != 0" :length="length" total-visible="10"
                                        v-model="publicSalePagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </div>
                            <div v-if="view == 'show'">
                                <v-card outlined class="mt-2">
                                    <v-card-title class="accent text--text">
                                        {{ assignedSale.sale_no }}
                                        <v-spacer></v-spacer>
                                        
                                        <v-btn icon @click="view = 'default'">
                                        <v-icon class="text--text"> mdi-close </v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="pa-3">
                                        <v-tabs show-arrows v-model="saleTab">
                                            <v-tabs-slider color="primary"></v-tabs-slider>

                                            <v-tab @click="activeTab = 'details'">
                                            <div class="text-none primary--text">Details</div>
                                            </v-tab>
                                            <v-tab @click="activeTab = 'saleItems'">
                                            <div class="text-none primary--text">
                                                Parts ({{ assignedSale.sale_parts.length }})
                                            </div>
                                            </v-tab>
                                        </v-tabs>
                                        <div v-if="activeTab == 'details'" class="mx-3 mt-7">
                                            <v-layout row wrap>
                                            <v-flex xs12 md6>
                                                <v-layout column>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs11>
                                                        <div class="primary--text"><b>Sale Details</b></div>
                                                    </v-flex>
                                                    <v-flex xs1>

                                                    </v-flex>
                                                    </v-layout>

                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Sale Code:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        {{
                                                        assignedSale.sale_code
                                                        }}
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Sale No:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        {{
                                                        assignedSale.sale_no
                                                        }}
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Status:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        {{
                                                        assignedSale.status.name
                                                        }}
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Total (Kshs):</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div align="left">
                                                        {{
                                                            assignedSale.total
                                                        }}
                                                        </div>
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Creation Date:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        {{
                                                        assignedSale.created_at
                                                        | moment("DD/MM/YYYY - hh:mm a")
                                                        }}
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>

                                                </v-layout>

                                                <v-layout column class="mt-5">
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs11>
                                                        <div class="primary--text"><b>Student Details:</b></div>
                                                    </v-flex>
                                                    <v-flex xs1>

                                                    </v-flex>
                                                    </v-layout>

                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Name:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        {{ assignedSale.sale_student.student.first_name }} {{
                                                        assignedSale.sale_student.student.middle_name }} {{
                                assignedSale.sale_student.student.last_name }}
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Email:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        {{ assignedSale.sale_student.student.email }}
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Phone #:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        {{ assignedSale.sale_student.student.phone }}
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>

                                                </v-layout>

                                            </v-flex>
                                            <v-flex xs12 md6>
                                                

                                                <v-layout column class="mt-5">
                                                <v-flex xs12>
                                                    <v-layout row wrap>
                                                    <v-flex xs11>
                                                        <div class="primary--text"><b>Payment Details</b></div>
                                                    </v-flex>
                                                    <v-flex xs1>

                                                    </v-flex>
                                                    </v-layout>

                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Ref Code:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div v-if="assignedSale.cash_payment!=null">
                                                            {{ assignedSale.cash_payment.cash_no }}
                                                        </div>
                                                        <div v-if="assignedSale.credit!=null">
                                                            {{ assignedSale.credit.credit_no }}
                                                        </div>
                                                        <div v-if="assignedSale.mpesa!=null">
                                                        {{
                                                            assignedSale.mpesa?.mpesa_callback_metadata.MpesaReceiptNumber
                                                        }}
                                                        </div>
                                                        <div v-if="assignedSale.sale_wallet_logs.length != 0">
                                                        <template v-for="(sale_wallet_log, index) in assignedSale.sale_wallet_logs">
                                                            <div>
                                                            {{ sale_wallet_log.wallet_log.transaction_number }}
                                                            </div>
                                                        </template>
                                                        </div>
                                                        
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 class="mt-5">
                                                    <v-layout row wrap>
                                                    <v-flex xs12 md2>
                                                        <div><b>Amount:</b></div>
                                                    </v-flex>
                                                    <v-flex xs12 md10>
                                                        <div v-if="assignedSale.mpesa!=null">
                                                        {{
                                                            assignedSale.mpesa?.mpesa_callback_metadata.Amount
                                                        }}
                                                        </div>
                                                        <div v-if="assignedSale.sale_wallet_logs.length != 0">
                                                        <template v-for="(sale_wallet_log, index) in assignedSale.sale_wallet_logs">
                                                            <div>
                                                            {{ sale_wallet_log.wallet_log.transaction_number }} - {{ sale_wallet_log.wallet_log.amount }}
                                                            </div>
                                                        </template>
                                                        </div>
                                                        
                                                    </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            </v-layout>
                                        </div>
                                        <div v-if="activeTab == 'saleItems'" class="mt-2">
                                            <v-layout column>
                                            <template v-for="(sale_part, index) in assignedSale.sale_parts">
                                                <v-flex xs12 :key="index">
                                                <div class="my-5">
                                                    <v-card elevation="0">
                                                    <v-layout row wrap>
                                                        <v-flex xs12 md4>
                                                        <v-img v-if="sale_part.part.image == null" class="background" contain
                                                            style="border-radius: 25px;" max-height="180" src="img/book-silhouette-vector-14.png">

                                                        </v-img>
                                                        <v-img max-height="180" contain v-else
                                                            :src="path + '/storage/part_pictures/' + sale_part.part.image"
                                                            style="border-radius: 25px;" class="background">

                                                        </v-img>
                                                        </v-flex>
                                                        <v-flex xs12 md8>
                                                        <div class="mx-10">
                                                            <v-layout column>
                                                            <v-flex xs12 class="mt-1">
                                                                <div class="ml-2 primary--text">
                                                                    <b>{{ sale_part.part.name }}</b>
                                                                </div>
                                                            </v-flex>

                                                            <v-flex xs12>
                                                                <div class="ml-5 mt-5">
                                                                <v-layout row wrap>
                                                                    <v-flex xs3>
                                                                    <b>Manufacturer:</b>
                                                                    </v-flex>
                                                                    <v-flex xs9>
                                                                    {{ sale_part.part.manufacturer.name }}
                                                                    </v-flex>
                                                                </v-layout>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                                <div class="ml-5 mt-5">
                                                                <v-layout row wrap>
                                                                    <v-flex xs3>
                                                                    <b>Serial No:</b>
                                                                    </v-flex>
                                                                    <v-flex xs9>
                                                                    {{ sale_part.part.serial_no }}
                                                                    </v-flex>
                                                                </v-layout>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                                <div class="ml-5 mt-5">
                                                                <v-layout row wrap>
                                                                    <v-flex xs3>
                                                                    <b>Quantity Purchased:</b>
                                                                    </v-flex>
                                                                    <v-flex xs9>
                                                                    {{ sale_part.quantity }}
                                                                    </v-flex>
                                                                </v-layout>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                                <div class="ml-5 mt-5">
                                                                <v-layout row wrap>
                                                                    <v-flex xs3>
                                                                    <b>Unit Price(Kshs):</b>
                                                                    </v-flex>
                                                                    <v-flex xs9>
                                                                    {{ sale_part.unit_price }}
                                                                    </v-flex>
                                                                </v-layout>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs12>
                                                                <div class="ml-5 mt-5">
                                                                <v-layout row wrap>
                                                                    <v-flex xs3>
                                                                    <b>Sub Total:</b>
                                                                    </v-flex>
                                                                    <v-flex xs9>
                                                                    {{ sale_part.sub_total }}
                                                                    </v-flex>
                                                                </v-layout>
                                                                </div>
                                                            </v-flex>
                                                            </v-layout>
                                                        </div>

                                                        </v-flex>
                                                    </v-layout>
                                                    </v-card>

                                                    <v-divider class="mt-10"></v-divider>
                                                </div>
                                                </v-flex>
                                            </template>
                                            </v-layout>
                                        </div>
                                        </div>
                                    </v-card-text>
                                    </v-card>
                            </div>
                        </div>
                    </v-flex>
                </v-card>
            </v-layout>
        </div>
    </div>
</template>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>
<script>
import Vue from "vue";
import axios from "axios"
import apiCall from "@/utils/api";
import { mapGetters, mapState, mapActions } from "vuex";
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00");
});

export default {
    components: {

    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            view: 'default',
            activeTab: 'details',
            saleTab: null,
            downloadIndex: null,
            downloadLoading: false
        };
    },

    created() {
        this.startPublicSaleLoader();
        this.fetchAllWallets();
        this.fetchPublicSales(this.publicSalePagination.current_page);
        this.startWalletLoader()
    },
    methods: {
        ...mapActions([
            "fetchAllWallets",
            "startWalletLoader",

            "fetchPublicSales",
            "startPublicSaleLoader",
            "stopWalletLogLoader",
            "filterPublicSales",
        ]),

        showSale(sale) {
            this.assignedSale = sale;
            this.view = "show";
        },

        downloadReceipt(receipt, index){
            this.downloadIndex = index
            this.downloadLoading = true

            axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
            axios({
                url: this.path+"/api/sale?type=userReceipt&sale_id=" + receipt.id,
                method: "GET",
                responseType: "blob",
            })
                    .then((resp) => {
                        this.downloadFile(resp, 'Receipt');
                        this.downloadLoading = false
                    })
                    .catch((error) => {
                        this.downloadLoading = false
                    });
        },
        downloadFile(response, filename) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            console.log('response.data', response.data)
            var newBlob = new Blob([response.data], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = filename + ".pdf";
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        },

        changePage() {
            window.scrollTo(0, 0);
            this.startPublicSaleLoader();
            if (this.filteredLearningAreas == true) {
                this.searchButtonDisabled = false;
                apiCall({
                    url:
                        "/api/sale?type=public&page=" +
                        this.publicSalePagination.current_page +
                        "&type=search&search=" +
                        this.searchTerm,
                    method: "GET",
                })
                    .then((resp) => {
                        this.filterPublicSales(resp);
                        this.stopWalletLogLoader();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.stopWalletLogLoader();
                        this.message = "An Error Occurred";
                        this.color = "error";
                        this.snackbar = true;
                    });
            } else {
                this.fetchPublicSales(this.publicSalePagination.current_page);
            }
        },

    },
    computed: {
        ...mapGetters([
            "publicSales",
            "publicSalePagination",
            "publicSaleLoader",

            "allWallets"
        ]),
        length: function () {
            return Math.ceil(
                this.publicSalePagination.total / this.publicSalePagination.per_page
            );
        },
    },
};
</script>
  