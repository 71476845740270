<template>
  <div class="dashboard grey lighten-3">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-dialog
      v-model="topUpDialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card class="elevation-1" max-width="600">
        <v-toolbar flat color="transparent">
          <div class="title primary--text">
            Top Up Wallet - Ksh({{ totalAmount - walletAmount }})
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="topUpDialog = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-if="walletTopUpLevel == 'one'">
            <v-layout column>
              <v-flex xs12>
                <div class="mt-2">
                  <b>M-Pesa Phone Number</b>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-form ref="topUpForm" v-model="valid" lazy-validation>
                  <v-text-field
                    class="background text_field"
                    dense
                    outlined
                    v-model="walletData.phone_number"
                    required
                  ></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  depressed
                  block
                  color="primary"
                  :disabled="!valid"
                  @click="topUpWallet"
                  :loading="loading"
                  class="text-none mt-10"
                >
                  Request STK Push
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="walletTopUpLevel == 'two'">
            <v-layout column>
              <v-flex xs12>
                <div class="mx-3">
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="confirmTopUp"
                    :loading="loading"
                    class="text-none mt-5"
                  >
                    Confirm Wallet Top Up
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="walletTopUpLevel == 'three'">
            <v-layout column>
              <v-flex xs12>
                <div>
                  <v-container fill-height fluid>
                    <v-row align="center">
                      <v-col class="text-center">
                        <v-alert prominent type="success">
                          <div>Payment Received. Wallet Balance Updated.</div>
                          <div>
                            <b>M-Pesa Ref:</b> {{ walletData.mpesa_code }}
                          </div>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-progress-linear
      v-if="cartLoader"
      height="2"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-layout row wrap>
      <v-flex xs12 md6>
        <div class="pa-5">
          <div
            style="
              margin: 4px, 4px;
              padding: 4px;
              width: auto;
              height: 86vh;
              overflow-x: hidden;
              overflow-y: scroll;
              text-align: justify;
            "
          >
            <v-card elevation="0" v-if="carts.length != 0">
              <v-card-title>
                <div v-if="view == 'default'">Student(s)</div>
                <div v-if="view == 'payment'" @click="view = 'paymentOptions'">
                  <v-btn icon class="accent">
                    <v-icon class="white--text">mdi-arrow-left-thick</v-icon>
                  </v-btn>
                </div>
                <div v-if="view == 'paymentOptions'" @click="view = 'default'">
                  <v-btn icon class="accent">
                    <v-icon class="white--text">mdi-arrow-left-thick</v-icon>
                  </v-btn>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <div v-if="view == 'default'">
                <v-card-text>
                  <div v-if="formData.students.length != 0">
                    <div>
                      <v-radio-group v-model="orderData.student_id">
                        <v-radio
                          v-for="student in formData.students"
                          :label="getText(student)"
                          :value="student.id"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div>
                      <v-btn
                        v-if="orderData.student_id != null"
                        depressed
                        block
                        large
                        color="primary"
                        @click="view = 'paymentOptions'"
                        class="text-none mt-10"
                      >
                        Proceed to Payment Options
                      </v-btn>
                      <v-btn
                        v-if="orderData.student_id == null"
                        disabled
                        block
                        large
                        color="primary"
                        class="text-none mt-10"
                      >
                        Proceed to Payment Options
                      </v-btn>
                    </div>
                  </div>
                  <div v-else align="center">
                    Unfortunately No Student has been added to your Account.
                    Kindly Contact the Admin.
                  </div>
                </v-card-text>
              </div>
              <div v-if="view == 'paymentOptions'">
                <v-card-text>
                  <div v-if="formData.students.length != 0">
                    <div>
                      <v-radio-group v-model="orderData.paymentOption">
                        <v-radio
                          :label="'M-Pesa - ' + totalAmount"
                          value="mpesa"
                        ></v-radio>
                        <v-radio
                          :label="'Wallet - (Bal. ' + walletAmount + ')'"
                          value="wallet"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div>
                      <v-btn
                        v-if="
                          walletAmount != 0 &&
                          walletAmount < totalAmount &&
                          orderData.paymentOption == 'wallet'
                        "
                        depressed
                        block
                        large
                        color="green"
                        @click="topUpDialog = true"
                        class="text-none mt-5 white--text"
                      >
                        Top Up Wallet (Ksh {{ totalAmount - walletAmount }})
                      </v-btn>
                      <v-btn
                        v-if="orderData.paymentOption == null"
                        depressed
                        block
                        large
                        disabled
                        class="text-none mt-5"
                      >
                        Proceed to Payment
                      </v-btn>
                      <v-btn
                        v-if="orderData.paymentOption == 'mpesa'"
                        depressed
                        block
                        large
                        color="primary"
                        @click="view = 'payment'"
                        class="text-none mt-5"
                      >
                        Proceed to Payment
                      </v-btn>
                      <v-btn
                        v-if="
                          orderData.paymentOption == 'wallet' &&
                          walletAmount >= totalAmount
                        "
                        depressed
                        block
                        large
                        color="primary"
                        @click="saveWalletOrder()"
                        :loading="loading"
                        class="text-none mt-5"
                      >
                        Complete Purchase
                      </v-btn>
                    </div>
                  </div>
                  <div v-else align="center">
                    Unfortunately No Student has been added to your Account.
                    Kindly Contact the Admin.
                  </div>
                </v-card-text>
              </div>
              <div v-if="view == 'payment'" class="pa-10">
                <v-layout column>
                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <div class="mt-2">
                          <b>M-Pesa Phone Number</b>
                        </div>
                      </v-flex>
                      <v-flex xs12 md8>
                        <div>
                          <v-form
                            ref="orderForm"
                            v-model="valid"
                            lazy-validation
                          >
                            <v-text-field
                              class="background text_field"
                              dense
                              outlined
                              v-model="orderData.phone_number"
                              required
                            ></v-text-field>
                          </v-form>
                        </div>
                        <div>
                          <v-btn
                            depressed
                            block
                            color="primary"
                            :disabled="!valid"
                            @click="saveOrder"
                            :loading="loading"
                            class="text-none mt-10"
                          >
                            Request STK Push
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
              <div v-if="view == 'confirm'">
                <v-card-title> CONFIRM PAYMENT </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <div>
                    <div class="mx-3">
                      <v-btn
                        depressed
                        block
                        large
                        color="primary"
                        :disabled="!valid"
                        @click="confirmPayment"
                        :loading="loading"
                        class="text-none mb-10"
                      >
                        Confirm Payment
                      </v-btn>

                      <v-btn
                        v-if="showCompletePaymentManuallyButton"
                        depressed
                        block
                        large
                        color="success"
                        :disabled="!valid"
                        @click="onCompletePaymentManuallyButtonClicked"
                        :loading="loading"
                        class="text-none mb-10"
                      >
                        Complete Payment Manually
                      </v-btn>

                      <ConfirmReceiptNumber
                        v-if="showCompletePaymentManuallySection"
                        :saleId="orderData?.id"
                         @receiptProcessed="onPaymentCompletedManually($event)"
                      />
                    </div>
                  </div>
                </v-card-text>
              </div>
              <div v-if="view == 'complete'">
                <v-container fill-height fluid>
                  <v-row align="center" class="my-15">
                    <v-col class="text-center my-16">
                      <v-alert prominent type="success">
                        <div>Payment Received. Order made Successfully.</div>
                        <div><b>M-Pesa Ref:</b> {{ orderData.mpesa_code }}</div>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-if="view == 'walletComplete'">
                <v-container fill-height fluid>
                  <v-row align="center" class="my-15">
                    <v-col class="text-center my-16">
                      <v-alert prominent type="success">
                        <div>Payment Received. Order made Successfully.</div>
                        <div><b>Sale No:</b> {{ orderData.mpesa_code }}</div>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card>
            <v-card elevation="0" v-else> </v-card>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 md6>
        <div class="white">
          <v-layout column class="mx-10">
            <v-flex xs12 class="mt-10">
              My Shopping Cart ({{ carts.length }})
            </v-flex>
            <v-flex xs12 class="mt-3">
              <div v-if="carts.length == 0">
                <v-card elevation="0">
                  <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                      <v-col class="text-center"> NO ITEM FOUND </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>
              <div v-else>
                <v-layout column>
                  <template v-for="(cart, index) in carts">
                    <v-flex xs12 :key="index">
                      <div class="my-5">
                        <v-card elevation="0">
                          <v-layout row wrap>
                            <v-flex xs12 md4>
                              <v-img
                                v-if="cart.part.image == null"
                                class="background"
                                contain
                                style="border-radius: 25px"
                                max-height="180"
                                src="img/book-silhouette-vector-14.png"
                              >
                              </v-img>
                              <v-img
                                max-height="180"
                                contain
                                v-else
                                :src="
                                  path +
                                  '/storage/part_pictures/' +
                                  cart.part.image
                                "
                                style="border-radius: 25px"
                                class="background"
                              >
                              </v-img>
                            </v-flex>
                            <v-flex xs12 md8>
                              <v-layout column>
                                <v-flex xs12 class="mt-1">
                                  <div class="ml-5 primary--text">
                                    {{ cart.part.name }}
                                  </div>
                                </v-flex>

                                <v-flex xs12>
                                  <div class="ml-5 accent--text">
                                    Kshs
                                    {{
                                      (cart.part.price * cart.quantity)
                                        | formatNumber
                                    }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>

                        <v-divider class="mt-10"></v-divider>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </div>
            </v-flex>
            <v-flex xs12 class="mt-3">
              <v-pagination
                v-if="length != 0"
                circle
                :length="length"
                total-visible="10"
                v-model="cartPagination.current_page"
                @input="changePage()"
              >
              </v-pagination>
            </v-flex>

            <!-- <v-flex xs12 class="mt-10">
              <v-layout row wrap>
                <v-flex xs6>
                  <b>Subtotal (Kshs)</b>
                </v-flex>
                <v-flex xs6>
                  <div align="right">
                    <b>{{ subtotalAmount }}</b>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex> -->

            <!-- <v-flex xs12 class="mt-8">
              <v-layout row wrap>
                <v-flex xs6>
                  <b>VAT -6% (Kshs)</b>
                </v-flex>
                <v-flex xs6>
                  <div align="right">
                    <b>{{ subtotalAmount * (6 / 100) }}</b>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex> -->
            <!-- <v-flex xs12 class="mt-12">
              <v-divider></v-divider>
            </v-flex> -->
            <v-flex xs12 class="mt-16">
              <v-layout row wrap>
                <v-flex xs6>
                  <div class="subtitle-1"><b>Total (Kshs)</b></div>
                </v-flex>
                <v-flex xs6>
                  <div align="right">
                    <div class="subtitle-1 mb-10 green--text">
                      <b>{{ totalAmount | formatNumber }}</b>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
        <div class="white mt-10 mb-1 pa-10">
          <div><b>PAYMENT OPTIONS</b></div>
          <v-layout column>
            <v-flex xs12 class="mt-10 mb-10 mx-2">
              <v-layout row wrap>
                <v-flex xs2>
                  <v-icon large class="black--text">mdi-credit-card</v-icon>
                </v-flex>
                <v-flex xs10>
                  <v-layout column>
                    <v-flex xs12>
                      <div><b>Wallet</b></div>
                    </v-flex>
                    <v-flex xs12>
                      <div class="font-weight-light">
                        Bal: Khs {{ allWallets.amount | formatNumber }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="mb-5 mx-2">
              <v-layout row wrap>
                <v-flex xs2>
                  <v-icon large class="black--text">mdi-cellphone-text</v-icon>
                </v-flex>
                <v-flex xs10>
                  <v-layout column>
                    <v-flex xs12>
                      <div><b>M-Pesa</b></div>
                    </v-flex>
                    <v-flex xs12>
                      <!-- <div class="font-weight-light">M-Pesa</div> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
import ConfirmReceiptNumber from "./confirmReceiptNumber.vue";

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00");
});

export default {
  components: {
    ConfirmReceiptNumber,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      loading: false,
      valid: true,
      phoneValid: true,
      selfieValid: true,
      idValid: true,
      message: "",
      color: "",
      delete: false,
      confirm: false,
      dialog: false,
      snackbar: false,
      topUpDialog: false,
      view: "default",
      walletTopUpLevel: "one",

      inputRules: [(v) => !!v || " "],

      searchTerm: null,
      filteredCarts: false,

      orderData: {
        id: null,
        sale_code: null,
        total: null,
        phone_number: null,
        student_id: null,
        mpesa_code: null,
        paymentOption: null,
      },

      walletData: {
        phone_number: null,
        amount: null,
        top_up_code: null,
        mpesa_code: null,
      },

      subtotalAmount: 0,
      totalAmount: 0,
      formData: null,

      walletAmount: null,

      showCompletePaymentManuallyButton: false,
      showCompletePaymentManuallySection: false,
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  watch: {
    allWallets() {
      this.calculatePayments();
    },
  },
  created() {
    if (this.carts.length == 0) {
    } else {
      this.orderData.phone_number = this.getProfile.phone;
      if (this.orderData.phone_number.startsWith("07")) {
        this.orderData.phone_number =
          "254" + this.orderData.phone_number.slice(1);
      } else if (this.orderData.phone_number.startsWith("7")) {
        this.orderData.phone_number = "254" + this.orderData.phone_number;
      } else if (this.orderData.phone_number.startsWith("01")) {
        this.orderData.phone_number =
          "254" + this.orderData.phone_number.slice(1);
      } else if (this.orderData.phone_number.startsWith("1")) {
        this.orderData.phone_number = "254" + this.orderData.phone_number;
      }

      this.walletData.phone_number = this.orderData.phone_number;

      window.scrollTo(0, 0);

      //this.startCartLoader();
      for (var i = 0; i < this.carts.length; i++) {
        this.subtotalAmount +=
          this.carts[i].quantity * parseInt(this.carts[i].part.price);
      }
      // var tax = this.subtotalAmount * (6 / 100)
      // this.totalAmount = this.subtotalAmount + tax

      this.totalAmount = this.subtotalAmount;

      this.getFormData();
      this.fetchAllWallets();
      this.calculatePayments();
    }
  },
  methods: {
    ...mapActions([
      "startCartLoader",
      "stopCartLoader",
      "filterCarts",
      "fetchCarts",

      "fetchAllWallets",
    ]),

    calculatePayments() {
      this.walletAmount = this.allWallets.amount;
      // if(this.totalAmount <= this.allWallets.amount){
      //   this.walletAmount = this.totalAmount
      // }else {
      //   this.walletAmount = 'UNAVAILABLE (Bal. Kshs '+this.allWallets.amount+ ')'
      // }
    },
    getFormData() {
      apiCall({
        url: "/api/cart?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "anErrorOccurredCouldntGetFormDataMsg";
          this.color = "error";
        });
    },
    getText(item) {
      var returnValue = "";

      if (item.first_name !== null) {
        returnValue += item.first_name + " ";
      }

      if (item.middle_name !== null) {
        returnValue += item.middle_name + " ";
      }

      if (item.last_name !== null) {
        returnValue += item.last_name;
      }

      return returnValue;
    },
    changePage() {
      this.startCartLoader();
      if (this.filteredCarts == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/cart?page=" +
            this.cartPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterCarts(resp);
            this.stopCartLoader();
          })
          .catch((error) => {
            console.log(error.response);
            this.stopCartLoader();
            this.message = "anErrorOccurredMsg";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchCarts(this.cartPagination.current_page);
      }
    },
    saveWalletOrder() {
      this.loading = true;
      this.orderData.total = this.totalAmount;
      apiCall({
        url: "/api/sale",
        data: this.orderData,
        method: "POST",
      })
        .then((resp) => {
          if (resp.status == 404) {
            this.message = resp.message;
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
          } else {
            this.orderData.id = resp.id;
            this.orderData.mpesa_code = resp.sale_no;
            this.message = "Order Made Successfully";
            this.color = "success";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;

            this.view = "walletComplete";
            // this.fetchCarts(this.cartPagination.current_page);
            this.fetchAllWallets();
            //this.$store.dispatch(USER_REQUEST);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.confirm = false;
        });
    },
    saveOrder() {
      if (this.$refs.orderForm.validate()) {
        confirm("Are you sure you want complete this order?") &&
          (this.confirm = true);
        if (this.confirm) {
          if (this.orderData.phone_number.startsWith("07")) {
            this.orderData.phone_number =
              "254" + this.orderData.phone_number.slice(1);
          } else if (this.orderData.phone_number.startsWith("7")) {
            this.orderData.phone_number = "254" + this.orderData.phone_number;
          } else if (this.orderData.phone_number.startsWith("01")) {
            this.orderData.phone_number =
              "254" + this.orderData.phone_number.slice(1);
          } else if (this.orderData.phone_number.startsWith("1")) {
            this.orderData.phone_number = "254" + this.orderData.phone_number;
          } else if (this.orderData.phone_number.startsWith("+254")) {
            this.orderData.phone_number = this.orderData.phone_number.slice(1);
          }

          this.loading = true;
          this.orderData.total = this.totalAmount;
          apiCall({
            url: "/api/sale",
            data: this.orderData,
            method: "POST",
          })
            .then((resp) => {
              this.orderData.id = resp.id;
              this.orderData.sale_code = resp.sale_code;
              this.message = "STK initiated";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;

              this.view = "confirm";
              // Show complete payment manually button after 30 seconds
              setTimeout(() => {
                this.showCompletePaymentManuallyButton = true;
              }, 30000);
              // this.fetchCarts(this.cartPagination.current_page);
              //this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.loading = false;
              this.confirm = false;
            });
        }
      }
    },
    topUpWallet() {
      if (this.$refs.topUpForm.validate()) {
        confirm("Are you sure you want to top up Wallet?") &&
          (this.confirm = true);
        if (this.confirm) {
          if (this.walletData.phone_number.startsWith("07")) {
            this.walletData.phone_number =
              "254" + this.walletData.phone_number.slice(1);
          } else if (this.walletData.phone_number.startsWith("7")) {
            this.walletData.phone_number = "254" + this.walletData.phone_number;
          } else if (this.walletData.phone_number.startsWith("01")) {
            this.walletData.phone_number =
              "254" + this.walletData.phone_number.slice(1);
          } else if (this.walletData.phone_number.startsWith("1")) {
            this.walletData.phone_number = "254" + this.walletData.phone_number;
          } else if (this.walletData.phone_number.startsWith("+254")) {
            this.walletData.phone_number =
              this.walletData.phone_number.slice(1);
          }

          this.loading = true;
          this.walletData.amount = this.totalAmount - this.walletAmount;
          apiCall({
            url: "/api/wallet",
            data: this.walletData,
            method: "POST",
          })
            .then((resp) => {
              this.message = "STK initiated";
              this.color = "orange";
              this.loading = false;
              this.snackbar = true;
              this.confirm = false;

              this.walletTopUpLevel = "two";

              this.walletData.top_up_code = resp.top_up_code;

              // this.fetchCarts(this.cartPagination.current_page);
              //this.$store.dispatch(USER_REQUEST);
            })
            .catch((error) => {
              this.loading = false;
              this.confirm = false;
            });
        }
      }
    },
    confirmPayment() {
      this.loading = true;
      apiCall({
        url: "/api/mpesa-confirm-payment",
        data: this.orderData,
        method: "POST",
      })
        .then((resp) => {
          if (resp.status == "error") {
            this.message = "Payment Not Completed Yet";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;

            this.view = "confirm";
          } else {
            this.message = "Payment Confirmed";
            this.color = "success";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;

            this.orderData.mpesa_code = resp.message.MpesaReceiptNumber;

            this.view = "complete";
          }

          //this.$store.dispatch(USER_REQUEST);
        })
        .catch((error) => {
          this.loading = false;
          this.confirm = false;
        });
    },
    confirmTopUp() {
      this.loading = true;
      apiCall({
        url: "/api/mpesa-confirm-top-up",
        data: this.walletData,
        method: "POST",
      })
        .then((resp) => {
          if (resp.status == "error") {
            this.message = "Payment Not Completed Yet";
            this.color = "error";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;
          } else {
            this.fetchAllWallets();

            this.message = "Payment Confirmed";
            this.color = "success";
            this.loading = false;
            this.snackbar = true;
            this.confirm = false;

            this.walletTopUpLevel = "three";
            this.walletData.mpesa_code = resp.message.MpesaReceiptNumber;
          }

          //this.$store.dispatch(USER_REQUEST);
        })
        .catch((error) => {
          this.loading = false;
          this.confirm = false;
        });
    },
    onCompletePaymentManuallyButtonClicked() {
      this.showCompletePaymentManuallyButton = false;
      this.showCompletePaymentManuallySection = true;
    },
    onPaymentCompletedManually({ receiptNumber }) {
      this.orderData.mpesa_code = receiptNumber;
      this.view = "complete";
    },
  },
  computed: {
    ...mapGetters([
      "carts",
      "cartPagination",
      "cartLoader",
      "getProfile",
      "allWallets",
    ]),
    length: function () {
      return Math.ceil(
        this.cartPagination.total / this.cartPagination.per_page
      );
    },
  },
};
</script>
