<template>
  <div>
    <v-container fluid class="px-0">
      <v-row>
        <v-col>
          <p class="text-h6 primary--text">Complete Payment Manually</p>
        </v-col>
      </v-row>
      <v-form ref="form" @submit.prevent="onFormSubmitted">
        <v-row>
          <v-col>
            <v-card elevation="0" class="header">
              <div>Enter Mpesa Receipt Number</div>
              <v-text-field
                outlined
                dense
                class="text_field background my-2"
                v-model="receiptNumber"
                :disabled="loading"
                :rules="[(v) => !!v || 'Please enter a receipt number']"
                :error-messages="errorMessage"
              >
              </v-text-field>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn
              color="success"
              depressed
              class="text-none"
              :disabled="loading"
              type="submit"
            >
              {{ loading ? "Checking" : "Check" }} Receipt Number
              <v-progress-circular
                v-if="loading"
                class="ml-4"
                indeterminate
                size="24"
                color="green"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="4000"
      top
      :color="snackbar.color"
    >
      <span>{{ snackbar.message }}</span>
      <template #action="{ attrs }">
        <v-btn v-bind="{ attrs }" icon @click="snackbar.open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import apiCall from "@/utils/api";

export default {
  props: {
    saleId: Number,
  },
  data: () => ({
    loading: false,
    errorMessage: null,
    receiptNumber: null,
    snackbar: {
      open: false,
      message: null,
      color: "success",
    },
  }),

  methods: {
    async onFormSubmitted() {
      const formValid = this.$refs.form.validate();
      if (!formValid) {
        return;
      }

      this.loading = true;
      this.errorMessage = null;
      try {
        const resp = await apiCall({
          url: "/api/mpesa-confirm-receipt",
          method: "POST",
          data: {
            receiptNumber: this.receiptNumber,
            saleId: this.saleId,
          },
        });
        // this.snackbar.message = "Payment processed";
        // this.snackbar.color = "success";
        // this.snackbar.open = true;
        this.$emit("receiptProcessed", {
          receiptNumber: resp.receiptNumber,
        });
      } catch (e) {
        const statusCode = e.response.status;
        const errorMessage = e.response.data?.status;
        if (statusCode === 400 && errorMessage) {
          this.errorMessage = errorMessage;
          this.snackbar.message = errorMessage;
          this.snackbar.color = "error";
          this.snackbar.open = true;
        }
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    receiptNumber() {
      this.errorMessage = null;
    },
  },
};
</script>
